<template>
  <div>
    <b-row>
      <b-col>
        <h4><span class="badge badge-pill badge-primary">{{$t('Question')}} {{index}}</span></h4>
        <label style="margin-top: 5px; margin-left: 5px;">
          {{detail.question}}
        </label>
      </b-col>
      <b-col>
        <!--*************************************text******************************************-->
        <b-form-input v-if="detail.questionType=='text'" type="text" v-model="detail.answer" />
        <!--*******************************textarea************************************************-->
        <b-form-textarea v-else-if="detail.questionType=='textarea'" rows="2" v-model="detail.answer" />
        <!--***********************************date*****************************************************-->
        <date-picker v-else-if="detail.questionType=='date'"
                     v-model="detail.answer"
                     :tag-name="'Date'"
                     :is-required="false" />
        <!--*************************************numeric***********************************************-->
        <b-form-input v-else-if="detail.questionType=='numeric'" type="number" v-model="detail.answer" />
        <!--*************************************rightleft*******************************************-->
        <b-row v-else-if="detail.questionType=='rightleft'">
          <b-col>
            <b-form-input :placeholder="$t('Right')"
                          type="text"
                          v-model="detail.answer[0]" />
          </b-col>
          <b-col>
            <b-form-input :placeholder="$t('Left')"
                          type="text"
                          v-model="detail.answer[1]" />
          </b-col>
        </b-row>
        <!--*************************************leftright*******************************************-->
        <b-row v-else-if="detail.questionType=='leftright'">
          <b-col>
            <b-form-input :placeholder="$t('Left')"
                          type="text"
                          v-model="detail.answer[0]" />
          </b-col>
          <b-col>
            <b-form-input :placeholder="$t('Right')"
                          type="text"
                          v-model="detail.answer[1]" />
          </b-col>
        </b-row>
        <!--************************************checkbox***********************************************-->
        <b-form-checkbox v-else-if="detail.questionType=='checkbox'"
                         v-model="detail.answer">
          {{option}}
        </b-form-checkbox>
        <!--************************option_multiple*****************************************************-->
        <div v-if="detail.questionType=='option_multiple'">
          <div v-for="(option,index) in detail.options" :key="index">
            <b-form-checkbox v-model="detail.answer" :value="option">
              {{option}}
            </b-form-checkbox>
          </div>
        </div>
        <!--************************option*****************************************************-->
        <div v-else-if="detail.questionType=='option'">
          <v-select transition="" v-model="detail.answer"
                    :options="detail.options">
          <template v-slot:no-options> {{$t('NoMatchingOptionsMessage')}} </template> </v-select>
        </div>
        <!--************************option*****************************************************-->
      </b-col>
    </b-row>
    <br />
    <hr />
  </div>
</template>


<script>
  import DatePicker from '../shared/DatePicker'

  export default {
    name: 'CustomerFormDetail',
    props: {
      detail: Object,
      index: Number
    },
    components: {
      DatePicker
    },
    data() {
      return {
        model: {

        }
      }
    },
    methods: {

    },
    watch: {

    },
    created() {

    },
    mounted() {

    }
  }
</script>
