<template>
  <button type="button"
          class="btn btn-light mb-3"
          @click="goBack()">
    <font-awesome-icon icon="fa fa-arrow-left" />&nbsp;{{$t('Cancel')}}
  </button>
</template>

<script>
  export default {
    props: {

    },
    methods: {
      goBack: function () {
        this.$router.go(-1)
      }
    },
    computed: {

    },
    mounted: function () {

    }
  };
</script>
