<template>
  <div>
    <b-row style="padding:25px;">
      <b-col>
        <img :src="logo" class="img-fluid" alt="logo">
      </b-col>
      <b-col style="text-align:right;"><back-button></back-button></b-col>
    </b-row>
    <div style="padding:20px 50px;">
      <div class="iq-card">
        <div class="iq-card-body">
          <b-row style="height: 28px;">
            <b-col cols="2">
              <h4 class="card-title">{{customerInfo.name}}</h4>
            </b-col>
            <b-col v-if="customerInfo.dateOfBirth!=null">
              <h5 class="card-title"><font-awesome-icon icon="fa fa-birthday-cake" />&nbsp;{{customerInfo.dateOfBirth |dateFormat}}&nbsp;({{customerInfo.age}})</h5>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <div style="padding:20px 50px;">
      <div class="iq-card">
        <div class="iq-card-header d-flex justify-content-between">
          <div class="iq-header-title">
            <h4 class="card-title">{{model.customerFormTemplateName}}</h4>
          </div>
        </div>
        <div class="iq-card-body">
          <customer-form-detail v-for="(item,index) in model.customer_FormAnswer"
                                :key="index"
                                :detail="item"
                                :index="index+1" />
          <b-row>
            <b-col style="text-align:right;">
              <b-overlay :show="isSubmitting"
                         spinner-small
                         spinner-variant="primary"
                         class="d-inline-block">
                <b-button :disabled="isSubmitting"
                          variant="primary"
                          @click="submitNewCustomerForm()">
                  {{ $t('Save') }}
                </b-button>
              </b-overlay>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import customerForm from '../../../services/customerForm'
  import customer from '../../../services/customer'

  import BackButton from '../../../components/shared/BackButton'
  import CustomerFormDetail from '../../../components/customerForm/CustomerFormDetail'

  export default {
    name: 'CustomerForm',
    props: {
      templateId: String
    },
    components: {
      BackButton,
      CustomerFormDetail
    },
    data() {
      return {
        model: {
          id: "",
          customerFormTemplateID: "",
          customerFormTemplateName: "",
          customerID: "",
          customer_FormAnswer: []
        },

        customerInfo: {},

        isSubmitting: false
      }
    },
    methods: {
      getCustomerForm() {
        customerForm.getCustomerForm(this.model.id, this.templateId).then((result) => {
          this.model = result;
          this.model.customerID = this.$router.currentRoute.params.customerId;

          this.model.customer_FormAnswer.forEach(function (item) {
            if (
              item.questionType == "rightleft" ||
              item.questionType == "leftright" ||
              item.questionType == "option_multiple"
            ) {
              if (item.answer && item.answer.length > 0) {
                item.answer = item.answer.split("/");
              }
              else {
                item.answer = [];
              }
            }
          });
        })
      },
      getCustomerInfo() {
        customer.getCustomerInfo(this.model.customerID).then((result) => {
          if (result && result.id == this.model.customerID) {
            this.customerInfo = result;
          }
        })
      },
      submitNewCustomerForm: function () {
        if (!this.model.customer_FormAnswer.some((s) => s.answer != null && s.answer != "")) {
          this.$toastr.error(this.$t('ValidationMessage.EmptyAndValidateError'));
          return;
        }

        this.isSubmitting = true;

        if (this.$router.currentRoute.name == 'customerFormFromMessage')
          this.model.branchID = this.$router.currentRoute.params.branchId;

        this.model.customer_FormAnswer.forEach(function (item) {
          if (
            item.questionType == "rightleft" ||
            item.questionType == "leftright" ||
            item.questionType == "option_multiple"
          ) {
            item.answer = item.answer.join("/");
          }
        });

        customerForm.submitNewCustomerForm(this.model)
          .then((result) => {
            this.isSubmitting = false;
            if (result) {
              this.$toastr.success(this.$t('Success'));
              this.$router.go(-1);
            }
            else {
              this.$toastr.error(this.$t('Error'));
            }
          })

      }
    },
    computed: {
      logo() {
        return require('../../../assets/images/logo-full-custom-size.png');
      }
    },
    watch: {

    },
    created() {
      this.$emit("update:layout", "");
    },
    mounted() {
      this.model.customerID = this.$router.currentRoute.params.customerId;

      if (this.$router.currentRoute.name != 'customerFormFromMessage')
        this.getCustomerInfo();


      this.model.id = this.$router.currentRoute.params.customerFormId;
      this.model.customerFormTemplateID = this.$router.currentRoute.params.templateId;

      this.getCustomerForm();

      //this.$store.dispatch("setBackgroundImage", "background-image:unset !important");
    }
  }
</script>
